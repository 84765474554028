import axios from 'axios';

const baseUrl = 'https://apps.kenes.com/Web2LeadService/';
//const baseUrl = 'http://localhost:49147/';


// const GET = url => {
//     return axios.get(`${baseUrl}/${url}`);
// }

// const headers = {};

const insertLeadDataFunc = (actionName, data, successCallback, failCallback) => {
    debugger;
    return axios
        .post(`${baseUrl}Values/${actionName}`, data)
        .then(response => {
            successCallback(response);
        })
        .catch(function (error) {
            failCallback(error);
            console.log(error);
        });
}

export const insertLeadData = (actionName, data, successCallback, failCallback) => {

    if (typeof grecaptcha !== 'undefined') {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6Lc4pIUUAAAAAG-r76A6RhcMTiI4Bh0JKi1Mv7qW', { action: 'submit' })
                .then(function (token) {
                    debugger;
                    if (data == null) {
                        data = {};
                    }
                    data['token'] = token;
                    insertLeadDataFunc(actionName, data, successCallback, failCallback);
                });
        });
    }

}
