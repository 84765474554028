import React, {useState} from 'react'
import {insertLeadData} from './fetch.js';
// import {Button, Container, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const KenesGetInTouchForm = () => {

    const interestList = [
        {
            id: -1,
            name: '',
        },
        {
            id: 0,
            name: 'Conference Management',
        },
        {
            id: 1,
            name: 'Virtual & hybrid solutions',
        },
        {
            id: 2,
            name: 'Association management',
        },
        {
            id: 3,
            name: 'Continuing medical education',
        },
        {
            id: 4,
            name: 'Anything else',
        },
    ];

    const mySubmitHandler = (event) => {
        debugger;
        event.preventDefault();

        insertLead('InsertCompanyLead', {
                firstName: event.target.firstName.value,
                lastName: event.target.lastName.value,
                email: event.target.email.value,
                participants: event.target.participants.value,
                company: event.target.company.value,
                eventWebsite: event.target.eventWebsite.value,
                budget: event.target.budget.value,
                //interest: event.target.interest.value,
                interest: document.getElementById('interest').value,
                message: event.target.message.value,
            },
            (result) => {
                debugger;
                alert('Thank you for your submission. Our representative will contact you shortly');
                window.location.href = 'https://kenes-group.com/contact-us/';
            },
            (error) => {
                debugger;
                alert("submit unseccessful. please try again in a few minutes.");
            }
        );

    }

    const insertLead = (actionName, data, successCallback, errorCallback) => {
        debugger;
        insertLeadData(
            actionName,
            data,
            (result) => {
                successCallback(result);
            },
            (error) => {
                errorCallback(error);
                debugger;
            });
    }
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [participants, setParticipants] = useState('');

    const [company, setCompany] = useState('');
    const [eventWebsite, setEventWebsite] = useState('');
    const [budget, setBudget] = useState('');
    const [interest, setInterest] = useState('');
    const [message, setMessage] = useState('');

    return (
        // <Container maxWidth="sm">
        //     <div style={{marginTop: 24,}}>
        <form onSubmit={mySubmitHandler} autoComplete="off">
            <table style={{borderSpacing: "50px 15px"}}>
                <tbody>
                <tr>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            First Name*</label>
                        <input
                            id="firstName"
                            required
                            type="text"
                            value={firstName}
                            name={"firstName " + Math.random()}
                            autoComplete="new-firstName"
                            onChange={(e) => setFirstName(e.target.value)}/>
                        </div>
                    </td>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            Last Name*</label>
                        <input
                            id="lastName"
                            required
                            type="text"
                            value={lastName}
                            name={"lastName " + Math.random()}
                            autoComplete="new-lastName"
                            onChange={(e) => setLastName(e.target.value)}/>
                        </div>
                    </td>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            Your Email*</label>
                        <input
                            id="email"
                            required
                            type="email"
                            value={email}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            name={"email " + Math.random()}
                            autoComplete="new-email"
                            onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                    </td>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            Expected Participants*</label>
                        <input
                            id="participants"
                            required
                            type="number"
                            value={participants}
                            name={"participants " + Math.random()}
                            autoComplete="new-participants"
                            onChange={(e) => setParticipants(e.target.value)}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            Association / Company*</label>
                        <input
                            id="company"
                            required
                            type="text"
                            value={company}
                            name={"company " + Math.random()}
                            autoComplete="new-company"
                            onChange={(e) => setCompany(e.target.value)}/>
                        </div>
                    </td>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            Your Event Website</label>
                        <input
                            id="eventWebsite"
                            type="text"
                            value={eventWebsite}
                            name={"website " + Math.random()}
                            autoComplete="new-website"
                            onChange={(e) => setEventWebsite(e.target.value)}/>
                        </div>
                    </td>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>
                            Approximate budget</label>
                        <input
                            id="budget"
                            type="text"
                            value={budget}
                            name={"budget " + Math.random()}
                            autoComplete="new-budget"
                            onChange={(e) => setBudget(e.target.value)}/>
                        </div>
                    </td>
                    <td>
                        <div style={{width: '200px'}}>
                        <label style={{display: 'block'}}>Interested in*</label>
                        <select
                            id="interest"
                            required
                            value={interest.id}
                            onChange={(e) => setInterest(e.target.value)}>
                            {interestList.map((interest, index) => {
                                return <option key={index}>{interest.name}</option>;
                            })}
                        </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="4">
                        <label style={{display: 'block'}}>
                            Your Message*</label>
                        <textarea
                            id="message"
                            required
                            maxLength={13000}
                            value={message}
                            name={"message " + Math.random()}
                            autoComplete="new-message"
                            style={{width: '100%', height: '200px'}}
                            onChange={(e) => setMessage(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td colSpan="4">
                        <input type="submit" value="Submit"/>
                        {/*<Button type="submit" variant="contained" color="primary">Submit</Button>*/}
                    </td>
                </tr>
                </tbody>
            </table>
        </form>

        // </div>
        // </Container>
    );
}

export default KenesGetInTouchForm;
