import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import KenesGetInTouchForm from './KenesGetInTouchForm';
// import App1 from './App';
import reportWebVitals from './reportWebVitals';


const rootElementElement = document.getElementById("kenesComponentContainer");
ReactDOM.render(
    <KenesGetInTouchForm />,
    rootElementElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
